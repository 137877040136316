// @ts-strict-ignore
import keyBy from 'lodash.keyby';

import { anyToBoolean } from 'helpers/boolean';
import { type KeyMap } from 'helpers/interface';
import { type CustomSectionDefinition } from 'interfaces/custom-section';
import { type IInboxMessage } from 'services/widget/message/inbox/inbox-message';
import { isLegacyApplication } from 'store/entities/applications/helpers/legacy-apps';

import {
  type IApplication,
  type IInstalledApps,
  type IApplicationElements,
  type IChatApplication,
  type IApplicationsState,
} from '../interfaces';

function createCustomSectionName(widgetId: string, sectionTitle: string): string {
  return `${widgetId}-${sectionTitle.replace(/\s+/g, '-')}`;
}

function getElements(payload: KeyMap): IApplicationElements {
  const elements: IApplicationElements = {
    widgetIds: [],
    widgets: {},
    buttonIds: [],
    buttons: {},
  };

  if (
    payload.elements &&
    (Object.prototype.hasOwnProperty.call(payload.elements, 'widgetIds') ||
      Object.prototype.hasOwnProperty.call(payload.elements, 'buttonIds'))
  ) {
    return payload.elements;
  }

  if (payload.elements && Array.isArray(payload.elements.buttons)) {
    elements.buttons = keyBy(
      payload.elements.buttons.map((button) => ({
        id: button.id,
        action: button.action,
        label: button.label,
        placement: button.placement,
        sourceUrl: button.sourceUrl,
        url: button.url,
        ...(button.widget && { widgetId: button.widget }),
        appId: payload.id,
        icon: payload.icons && payload.icons.small,
      })),
      'id',
    );

    elements.buttonIds = Object.keys(elements.buttons);
  }

  if (payload.elements && Array.isArray(payload.elements.widgets)) {
    elements.widgets = keyBy(
      payload.elements.widgets.map((widget) => ({
        id: widget.id,
        placement: widget.placement,
        url: widget.url,
        shortcut: widget.shortcut,
        name: payload.name,
        appId: payload.id,
        appSlug: payload.slug,
        icon: payload.icons?.small,
        interfaceIcon: payload.interfaceIconsDefault?.small,
        interfaceDarkIcon: payload.interfaceIconsDark?.small,
        alternativeFullscreenPath: payload.customProps?.alternativeFullscreenPath,
        isInternalProduct: anyToBoolean(payload.customProps?.internalProduct),
      })),
      'id',
    );

    elements.widgetIds = Object.keys(elements.widgets);
  }

  return elements;
}

// This will be moved to services/api space when we implement fetching outside
// Backbone models.
export function applicationApiSerializer(apiPayload: KeyMap): IApplication {
  const app: IApplication = {
    id: apiPayload.id,
    name: apiPayload.name,
    elements: getElements(apiPayload),
    icons: null,
    interfaceIcons: null,
    interfaceDarkIcons: null,
    isService: false,
    hasSettingsHidden: false,
    isUninstallableOnlyByOwners: false,
    isPaidApp: false,
    isInternalProduct: false,
    directInstallUrl: null,
    description: apiPayload.shortDescription,
    publishedAt: apiPayload.publishedAt,
    tutorialUrl: apiPayload.tutorialUrl,
    ownerId: apiPayload.ownerId,
    slug: apiPayload.slug,
    payment: apiPayload.payment,
    goToWebsiteUrl: apiPayload.customProps?.goToWebsiteUrl,
    customProps: null,
  };

  if (apiPayload.icons) {
    app.icons = {
      large: apiPayload.icons?.large,
      small: apiPayload.icons?.small,
    };
  }

  if (apiPayload.interfaceIconsDefault) {
    app.interfaceIcons = {
      large: apiPayload.interfaceIconsDefault?.large,
      small: apiPayload.interfaceIconsDefault?.small,
    };
  }

  if (apiPayload.interfaceIconsDark) {
    app.interfaceDarkIcons = {
      large: apiPayload.interfaceIconsDark?.large,
      small: apiPayload.interfaceIconsDark?.small,
    };
  }

  if (apiPayload.logo_url) {
    app.icons = {
      large: null,
      small: apiPayload.logo_url,
    };
  }

  if (apiPayload.authorization && apiPayload.authorization.clientId) {
    app.clientId = apiPayload.authorization.clientId;
  }

  if (apiPayload.payment && apiPayload.payment.price) {
    app.isPaidApp = true;
  }

  if (apiPayload.directInstallUrl) {
    app.directInstallUrl = apiPayload.directInstallUrl;
  }

  if (apiPayload.customProps) {
    if (anyToBoolean(apiPayload.customProps.hasSettingsHidden)) {
      app.hasSettingsHidden = true;
    }

    if (anyToBoolean(apiPayload.customProps.internalProduct)) {
      app.isInternalProduct = true;
    }

    if (anyToBoolean(apiPayload.customProps.onlyOwnerCanInstall)) {
      app.isUninstallableOnlyByOwners = true;
    }

    if (anyToBoolean(apiPayload.customProps.isService)) {
      app.isService = true;
    }

    if (anyToBoolean(apiPayload.customProps.agentAppReport)) {
      app.isReportApp = true;
    }

    if (anyToBoolean(apiPayload.customProps.alternativeFullscreenPath)) {
      app.alternativeFullscreenWidgetPath = apiPayload.customProps.alternativeFullscreenPath;
    }
  }

  if (isLegacyApplication(app.id)) {
    app.description = apiPayload.shortDescription || apiPayload.description_short;
    app.icons = {
      large: (apiPayload.icons && apiPayload.icons.large) || apiPayload.logo_url,
      small: (apiPayload.icons && apiPayload.icons.small) || apiPayload.logo_url,
    };
    app.tutorialUrl = apiPayload.tutorialUrl || apiPayload.tutorial_url;
    app.publishedAt = apiPayload.publishedAt || 'legacy-app';
    app.slug = app.id;
  }

  if (apiPayload.customProps) {
    app.customProps = apiPayload.customProps;
  }

  return app;
}

function chatApplicationApiSerializer(apiPayload: KeyMap): IChatApplication {
  return {
    clientId: apiPayload?.authorization?.clientId || apiPayload.clientId,
    isChatChannel: apiPayload.customProps && apiPayload.customProps.isChannel,
    channelIcon: apiPayload.customProps.channelIcon || null,
    disabledFeatures:
      apiPayload.customProps &&
      apiPayload.customProps.disabledFeatures &&
      apiPayload.customProps.disabledFeatures.split(',').map((disabledFeature) => disabledFeature.trim()),
  };
}

export function applicationsStateSerializer(currentState: IInstalledApps, apiApplication): IInstalledApps {
  const application = applicationApiSerializer(apiApplication);
  const currentApplication = currentState.byIds[application.id];

  return {
    byIds: {
      ...currentState.byIds,
      [apiApplication.id]: currentApplication || application,
    },
    channels: {
      byClientIds: {
        ...currentState.channels.byClientIds,
        ...(apiApplication.customProps?.isChannel && {
          [application.clientId]: chatApplicationApiSerializer(apiApplication),
        }),
      },
    },
  };
}

export function customSectionMessageSerializer(
  currentState: IApplicationsState,
  messageApp: IInboxMessage,
): KeyMap<CustomSectionDefinition> {
  const { data, pluginId } = messageApp;
  const name = createCustomSectionName(pluginId, data?.title);

  return {
    ...currentState.privateWidgets.byIds,
    [name]: {
      title: data.title,
      components: data?.components,
      id: name,
    },
  };
}
